import React from "react";
import {getUserData} from "../../../../lib/Auth";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import apiRequest from "../../../../lib/Api";
import SimpleCard from "../../../../components/Cards/SimpleCard";


class ItensProntuario extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            me: getUserData(),
            specialist: {id: null},
            working: false,
            exams: this.props.exams || {}
        }
        ;
    }

    renderExamStatus(exam) {
        let status = 'Não Iniciado';
        let examData = this.state.exams[exam];

        let icon = 'fa fa-play-circle';
        let colorIcon = "blue"
        if (examData && examData.status !== null) {
            if (examData.status === 'done') {
                icon = 'fa fa-check-circle';
                colorIcon = "green";
            }

            status = examData.status === 'done' ? ' Concluído em ' : 'Iniciado em ';
            status += moment(examData.due_date, 'YYYY-MM-DD').format("DD/MM/YYYY");
        }

        return (
            <>
                <i className={icon} style={{color: colorIcon, fontSize: '25px'}}></i>&nbsp;<span>{status}</span>
            </>
        );
    }

    async componentDidMount() {
        if (!this.props.exams) {
            const responseMedicalRecord = await apiRequest(`/patient/${this.state.me.id}/medical-record`, {method: "GET"})
            this.setState({exams: responseMedicalRecord.data.exams})
        }
    }

    renderNutrideckContent() {

        return (
            <>
                <Col md='6'>
                    <SimpleCard
                        title="Conteúdo"
                        subject="Amplie seus conhecimentos em nutrição"
                        routeButton="/admin/nutrideck/content"
                        titleButton="Conhecer"
                    />
                </Col>
                <Col md='6'>
                    <SimpleCard
                        title="Parceiros Nutrideck"
                        subject="Mais praticidade e economia no seu plano alimentar"
                        routeButton="/admin/nutrideck/partners"
                        titleButton="Conhecer"
                    />
                </Col>
            </>
        );
    }

    renderPlanoAlimentar() {

        let isDisabled = false;
        if (!this.state.exams.plano_alimentar || this.state.exams.plano_alimentar.status !== 'done') {
            isDisabled = true;
        }

        return (
            <>
                <Col md='6'>
                    <SimpleCard
                        title="Plano Alimentar"
                        subject={this.renderExamStatus('plano_alimentar')}
                        routeButton="/admin/patient/plano-alimentar"
                        titleButton="Visualizar"
                        isDisabled={isDisabled}
                    />
                </Col>
                <Col md='6'>
                    <SimpleCard
                        title="Rituais"
                        subject="Transforme sua relação com os alimentos"
                        routeButton="/admin/nutrideck/challenge"
                        titleButton="Visualizar"
                    />
                </Col>
            </>
        )
    }

    renderButtonVisualizar(path) {

        if (this.state.me.role !== 'patient') {
            return (
                <Button color="primary" tag={Link} to={path}>
                    Visualizar
                </Button>
            )
        }
    }

    renderMetasCombinados() {

        return (
            <Col md='12'>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <b>Metas e Combinados</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.renderExamStatus('metas_combinados')}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="primary"
                                        tag={Link}
                                        to={`/admin/patient/me/forms/metas_combinados`}>
                                    Visualizar
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }

    render() {

        return (
            <>
                <div className="text-center">

                    <Row>
                        {this.renderPlanoAlimentar()}
                    </Row>
                    <Row>
                        {this.renderMetasCombinados()}
                    </Row>
                    <Row>
                        {this.renderNutrideckContent()}
                    </Row>
                </div>
            </>
        );
    }
}

export default ItensProntuario;
