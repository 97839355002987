import React from "react";

// reactstrap components
import {Badge, Button, Col, Row} from "reactstrap";
import BotDropChat from "../../views/pages/BotDropChat";
import Portal from "reactstrap/src/Portal";

const PING_INTERVAL = 30000;  // 1 min
// for testing
class ButtonChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user || {user: {name: ''}},
            userList: [],
            isOpen: false,
            messageCount: this.props.messageCount || 0,
            label: this.props.label || ''
        }
    }

    openChat = () => {

        let width = window.outerWidth;
        this.setState({isOpen: true})
        if (width < 500) {
            document.getElementById('root').style.display = 'none';
        }
    }
    closeChat = () => {

        let width = window.outerWidth;
        this.setState({isOpen: false})
        if (width < 500) {
            document.getElementById('root').style.display = '';
        }

        if (this.props.onCloseCallback) {
            this.props.onCloseCallback();
        }

    }

    renderChat() {

        if (!this.state.isOpen) {
            return (
                <></>
            )
        }
        return (
            <Portal>
                <div className="content" style={{backgroundColor:'white', height: '100%'}}>
                    <div className="boot-drop">
                        <div className="chat-panel">
                            <div className="chat-header">
                                        <div className='title'>
                                            <h5>Chat</h5>
                                        </div>
                                        <div id="dismiss">
                                            <i className="fa fa-times" onClick={this.closeChat}/>
                                        </div>

                            </div>
                            <BotDropChat user_id={this.state.user.id}/>
                        </div>
                    </div>
                </div>
            </Portal>
        )
    }

   renderMessageCount() {
        if (this.state.messageCount == 0) {
            return <></>
        }
        return  <Badge color="danger" style={{position: 'absolute'}} pill>{this.state.messageCount}</Badge>
   }
    render() {
        return (
            <>
                <Button
                    color="primary"
                    style={{wordWrap:'nowrap'}}

                    onClick={this.openChat}
                > {this.state.label} {"  "}
                    <i style={{fontSize: '15px'}} className="fa fa-comment"/>
                    {this.renderMessageCount()}
                </Button>
                {this.renderChat()}
            </>
        );

    }
}
export default ButtonChat
