import React from "react";

// reactstrap components
import apiRequest from "../../../../lib/Api";
import './LancamentoAlimento.scss';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";
import ImportMealFood from "./ImportMealFood";
import ReactBSAlert from "react-bootstrap-sweetalert";

class ImportMeal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalFood: false,
            foodsMealSelected: null,
            patientId: this.props.patient_id,
            currentMeal: '',
            meals: [],
            mealUuid: this.props.mealUuid || null,
            show: this.props.show || false
        }
    }

    async componentDidMount() {

        if (this.props.patient_id === null && this.props.mealUuid === null) {
            return;
        }
        await apiRequest(`/patient/${this.props.patient_id}/plan-meal`, {method: "GET"})
            .then((response) => {
                const {data} = response.data;

                let meals = [];
                data.meals.map(meal => {
                    if (meal.uuid !== this.props.mealUuid) {
                        meals.push(meal);
                    }
                });

                this.setState({meals: meals});
            })
    }

    toggleModalFoods = () => {
        this.setState({showModalFood: !this.state.showModalFood, selectedMealToShow: null});
    }

    showFoods = async (meal) => {
        console.log(meal)

        const endPoint = `/patient/${this.props.patient_id}/plan-meal/${meal.meal_plan}/meals/${meal.uuid}`;
        await apiRequest(endPoint, {method: "GET"})
            .then((response) => {
                const {data} = response.data;
                this.setState({showModalFood: true, foodsMealSelected: data});
            })
    }

    hideAlertDeleteMeal = () => {
        this.setState({confirmImportMeal: null});
    };

    confirmImportMeal = (meal) => {

        const titleConfirm = `Tem certeza que deseja a refeição ${meal.name}?`;
        this.setState({
            confirmImportMeal: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-250px" }}
                    title={titleConfirm}
                    onConfirm={() => this.importMeal(meal)}
                    onCancel={this.hideAlertDeleteMeal}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Sim, quero importar!"
                    cancelBtnText="Cancelar"
                    showCancel
                >
                    Todos os alimentos da refeição selecionada serão importados na refeição atual.
                </ReactBSAlert>
            )
        });
    };

    importMeal = async (meal) => {

        const endPoint = `/patient/${this.props.patient_id}/plan-meal/${meal.meal_plan}/meals/${this.props.mealUuid}/import/${meal.uuid}`;

        await apiRequest(endPoint, {method: "POST"})
            .then((response) => {
                this.setState({showModalFood: false, confirmImportMeal: null, foodsMealSelected: null});
                this.props.callbackCloseModal();
                this.props.notify({type: 'success', message: `Refeição importada com sucesso.`});
            });
    }

    renderMealRow = (meal) => {

        if (meal.uuid === this.props.mealUuid) {
            return;
        }

        return (
            <tr key={meal.uuid}>
                <td>{meal.name}</td>
                <td>
                    <Button color="primary" onClick={() => {this.showFoods(meal)}} aria-label={"Ver alimentos"}>
                        <i className={"nc-icon nc-zoom-split"}></i>
                        Ver Alimentos
                    </Button>
                    {' '}
                    <Button color="purle-nutrideck" onClick={() => {this.confirmImportMeal(meal)}}>
                        <i className={"nc-icon nc-check-2"}></i>
                        Importar
                    </Button>
                </td>
            </tr>
        );
    }

    renderNoMeal = () => {

        if (!this.state.meals || this.state.meals.length === 0) {
            return (
                <tr>
                    <td colSpan={2}>
                        <h6>Nenhuma refeição disponível para importação.</h6>
                    </td>
                </tr>
            );
        }
    }

    render() {

        return (
            <>
                {this.state.confirmImportMeal}
                <ImportMealFood
                    show={this.state.showModalFood}
                    meal={this.state.foodsMealSelected}
                    callbackCloseModal={this.toggleModalFoods}
                />
                <Modal isOpen={this.props.show} size="lg">
                    <ModalHeader>
                        Importar Refeição
                    </ModalHeader>
                    <ModalBody>
                        <p>Selecione abaixo a refeição que deseja importar.</p>
                        <Table hover>
                            <thead>
                            <tr>
                                <th style={{"width": '60%'}}>Refeição</th>
                                <th style={{"width": '40%'}}>Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderNoMeal()}
                            {this.state.meals.map(meal => this.renderMealRow(meal))}
                            </tbody>
                        </Table>
                    </ModalBody>

                    <ModalFooter>
                        <Button color='error' onClick={() => {
                            this.setState({show: false});
                            this.props.callbackCloseModal();
                        }}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
 export default withNotify(ImportMeal);
