import React from "react";

// reactstrap components
import apiRequest from "../../../../lib/Api";
import './LancamentoAlimento.scss';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import withNotify from "../../../../lib/NotificationWrapper";

class ImportMealFood extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderFoodRow = (food) => {

        return (
            <tr key={food.uuid}>
                <td>{food.name}</td>
                <td>
                    {"Medida: "+ food.home_measure}
                    <br />
                    {"Quantidade: " + food.quantity}
                    <br />
                    {"Kcal: " + food.calories}
                </td>
                <td>
                    {"Proteínas: " + food.proteinas}
                    <br />
                    {"Lipídios: " +food.lipidios}
                    <br />
                    {"Carboidratos: "+ food.carboidratos}
                </td>
            </tr>
        );
    }

    renderNoFood = () => {

        if (!this.props.meal || this.props.meal.foods.length === 0) {
            return (
                <tr>
                    <td colSpan={2}>
                        <h6>Nenhum alimento lançado para a refeição selecionada.</h6>
                    </td>
                </tr>
            );
        }
    }

    render() {

        if (this.props.meal === null) {
            return <></>;
        }

        return (
            <>
                <Modal isOpen={this.props.show} size="lg">
                    <ModalHeader>
                        Refeição: {this.props.meal ? this.props.meal.name : ''}
                    </ModalHeader>
                    <ModalBody>
                        <Table hover>
                            <thead>
                            <tr>
                                <th style={{"width": '30%'}}>Alimento</th>
                                <th style={{"width": '70%'}} colSpan={2}>
                                    Informação Nutricional
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderNoFood()}
                            {this.props.meal.foods.map(food => this.renderFoodRow(food))}
                            </tbody>
                        </Table>
                    </ModalBody>

                    <ModalFooter>
                        <Button color='error' onClick={() => {
                            this.setState({show: false});
                            this.props.callbackCloseModal();
                        }}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
 export default withNotify(ImportMealFood);
