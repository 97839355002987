import React from "react";

// reactstrap components
import InputError from "../../../components/InputError";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Row,
    Table
} from "reactstrap";
import apiRequest from "../../../../lib/Api";
import uuid from 'react-uuid'
import AsyncTypeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import './LancamentoAlimento.scss';
import {Link} from "react-router-dom";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import withNotify from "../../../../lib/NotificationWrapper";
import foodGroups from "../../../../variables/foodGroups";
import Select from "react-select";
import HeaderProntuario from "../../../../components/Header/HeaderProntuario";
import ImportMeal from "./ImportMeal";

class LancamentoAlimento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            origin_food: '',
            working: false,
            foods: [],
            errors: {},
            deleteId: '',
            isLoading: false,
            activeTable: 'all',
            showModalImportMeal: false,
            search: {
                isLoading: false,
                options: [],
                query: '',

            },

            model: {
                foods: [],
                name: ''
            },
            food_model: {
                name: '',
                quantity: '',
                home_measure: '',
                calories: 0,
                proteinas: 0,
                lipidios: 0,
                carboidratos: 0,
                origem: '',
                grupo: {}
            },
            alternative_food: {
                name: '',
                quantity: '',
                home_measure: '',
                calories: 0,
                proteinas: 0,
                lipidios: 0,
                carboidratos: 0,
                origin: '',
                alternatives: []

            },
            modal: false,
            addingAlternatives: false,
            showDeleteConfirmation: false,
        }
        this._typeahead = null;
        this._typeaheadAlternative = null;
        this.foodGroups = foodGroups.map(group =>
            ({label: group.name, value: group.id})
        );

        this.nutrientsProportions = [
            {name: 'calorias', proportion: 1, porcentagem: 0, total: 0},
            {name: 'lipideos', proportion: 9, porcentagem: 0, total: 0},
            {name: 'proteinas', proportion: 4, porcentagem: 0, total: 0},
            {name: 'carboidratos', proportion: 4, porcentagem: 0, total: 0}
        ];

    }

    get id() {
        if (this.props.match.params) {
            let {id} = this.props.match.params;
            return id;
        }
        return null;
    }

    get mealId() {
        if (this.props.match.params) {
            let {refeicao} = this.props.match.params;
            return refeicao;
        }
        return null;
    }

    get planId() {
        if (this.props.match.params) {
            let {plano} = this.props.match.params;
            return plano;
        }
        return null;
    }

    toggleModalImportMeal = () => {
        const {showModalImportMeal} = this.state;
        this.setState({showModalImportMeal: !showModalImportMeal});
        this.getFoodsMeal();
    }

    toogleAlternatives = () => {
        this.setState({addingAlternatives: !this.state.addingAlternatives})
    }

    toggleConfirmation = (show) => {
        this.setState({showDeleteConfirmation: show});
    }

    async componentDidMount() {
        await this.getFoodsMeal();
    }

    getFoodsMeal = async () => {
        await apiRequest(`/patient/${this.id}/plan-meal/${this.planId}/meals/${this.mealId}`, {method: "GET"})
            .then((response) => {
                const {data} = response.data;
                let model = {
                    foods: data.foods,
                    name: data.name
                }
                this.setState({model: model});
            })
    }

    delete = () => {
        let item = this.state.deleteId;
        let model = this.state.model;
        if (!this.state.addingAlternatives) {
            const totalFoods = model.foods.length;
            for (let i = 0; i < totalFoods; i++) {
                if (model.foods[i].uuid === item) {
                    model.foods.splice(i, 1);
                    break;
                }
            }
        } else {

            const totalFoods = this.state.alternative_food.alternatives.length;
            for (let i = 0; i < totalFoods; i++) {
                if (this.state.alternative_food.alternatives[i].uuid === item) {
                    this.state.alternative_food.alternatives.splice(i, 1);
                    break;
                }
            }
        }
        this.setState({model: model, deleteId: null});
    }

    requestData = async query => {
        let data = {
            search: query,
            table: this.state.activeTable,
        };
        await apiRequest("/foods", {method: "GET", data})
            .then(response => {
                const {data} = response.data;
                let search = this.state.search;
                search.options = data;
                this.setState({search: search});
            });
    }

    filterChange = (event) => {
        let value = event.target.value;
        this.setState({filter: value});
    }

    executeFilter = (event) => {
        event.preventDefault();
        this.requestData();
    }

    _handleSearch = value => {

        const {query} = this.state.search;
        this.setState({iswLoading: true});
        this.requestData(query);
    };

    _handleInputChange = query => {
        let search = this.state.search;
        search.query = query
        this.setState({search: search});
    };

    _handlePagination = (e, shownResults) => {
        const {query} = this.state.search;
        this.setState({isLoading: true});
        this.requestData(query);
    };

    handleChange = (selectedOptions, origin) => {
        if (selectedOptions) {
            let food = selectedOptions[0];
            if (!food) {
                return;
            }
            if (!origin) {
                origin = 'foods'
            }
            food.home_measure = '';
            food.calories = 0;
            food.lipidios = 0;
            food.proteinas = 0;
            food.carboidratos = 0;
            food.uuid = uuid();
            food.quantity = '';
            food.alternatives = [];

            let model = this.state.model;
            let search = this.state.search;
            search.query = '';
            if (origin === 'foods') {
                model.foods.push(food);
                this._typeahead.clear();
            }
            if (origin === 'alternatives') {
                let alternativeFood = this.state.alternative_food;
                alternativeFood.alternatives = alternativeFood.alternatives ? alternativeFood.alternatives : [];
                alternativeFood.alternatives.push(food);
                this.setState({alternative_food: alternativeFood}, () => this._typeaheadAlternative.clear());
            }

            this.setState({model: model, search: search});

        }
    }

    handleChangeFood = (name, value) => {
        if (typeof name === 'object' && typeof name.target === 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }

        let modelFood = this.state.food_model;
        modelFood[name] = value;
        this.setState({food_model: modelFood});

        if (this.state.errors[name]) {
            this.setState({errors: {...this.state.errors, [name]: ''}});
        }
    }

    updateMeasure(item, value, origin) {

        if (!origin) {
            origin = 'foods'
        }

        let model = this.state.model;

        if (origin === 'foods') {
            model.foods = model.foods.map(food => {
                if (food.uuid === item) {
                    food.home_measure = value.measure;
                    food.home_measure_selected = value;
                    food.home_measure_unit = Number(value.value.unit);
                }
                return food;
            });
            this.setState({model: model});
        }
        if (origin === 'alternatives') {

            let model = this.state.alternative_food;
            model.alternatives = model.alternatives.map(food => {
                if (food.uuid === item) {
                    food.home_measure = value.measure;
                    food.home_measure_selected = value.value;
                    food.home_measure_unit = Number(value.value.unit);
                }
                return food;
            });
            this.setState({alternative_food: model});
        }
    }

    updateFood(item, quantity, value, origin) {

        if (!origin) {
            origin = 'foods'
        }
        let foodsToUpdate = [];
        let model = {};
        if (origin === 'foods') {
            model = this.state.model;
            foodsToUpdate = model.foods;
        }
        if (origin === 'alternatives') {
            model = this.state.alternative_food;
            foodsToUpdate = model.alternatives;
        }

        let foods = foodsToUpdate.map(food => {

            if (food.uuid === item) {

                value = Number(value);
                food.quantity = value || '';
                let baseCalculo = Number(food.quantity) * Number(food.home_measure_unit);
                food.calories = (Number(food.attributes.energia_kcal.quantity / 100) * baseCalculo).toFixed(2);
                food.lipidios = (Number(food.attributes.lipideos.quantity / 100) * baseCalculo).toFixed(2);
                food.proteinas = (Number(food.attributes.proteina.quantity / 100) * baseCalculo).toFixed(2);
                food.carboidratos = (Number(food.attributes.carboidrato.quantity / 100) * baseCalculo).toFixed(2);

            }
            return food;
        })
        if (origin === 'foods') {
            model.foods = foods;
            this.setState({model: model});
        }
        if (origin === 'alternatives') {
            model.alternatives = foods;
            this.setState({alternative_food: model});
        }
    }

    /**
     * modal
     */
    closeModal = () => {
        this.setState({modal: false});
    }

    openModal = () => {
        this.setState({modal: true});
    }

    saveFoods = async () => {
        // if (this.state.model.foods.length === 0) {
        //     this.props.notify({type: 'error', message: 'Ao menos um alimento deve ser informado.'});
        //     return;
        // }
        let data = {
            data: {
                foods: []
            },
            method: 'post'
        }
        let endPoint = `/patient/${this.id}/plan-meal/${this.planId}/meals/${this.mealId}/foods`;
        for (const foodAdded of this.state.model.foods) {

            const food = {
                id: foodAdded.id,
                name: foodAdded.name,
                proteinas: foodAdded.proteinas,
                uuid: foodAdded.uuid,
                alternatives: foodAdded.alternatives ? foodAdded.alternatives : [],
                lipidios: foodAdded.lipidios,
                calorias: foodAdded.calories,
                carboidratos: foodAdded.carboidratos,
                quantity: foodAdded.quantity,
                home_measure: foodAdded.home_measure_selected.value.measure,
                home_measure_selected: foodAdded.home_measure_selected.value
            }
            if (food.quantity === 0 || food.quantity === '') {
                this.props.notify({
                    type: 'error',
                    message: `O alimento ${food.name} deve ter uma quantidade maior que zero.`
                });
                return;
            }
            data.data.foods.push(food);
        }

        await apiRequest(endPoint, data).then((response) => {
            this.props.notify({type: 'success', message: 'Refeição salva com sucesso.'});
        });
    }

    maybeDelete = (id) => {
        this.setState({deleteId: id});
        this.toggleConfirmation(true);
    }

    addNewFood = async () => {

        let data = {
            data: {...this.state.food_model},
            method: 'POST',
        }
        if (data.data.grupo) {
            data.data.grupo = this.state.food_model.grupo.value;
        }

        let endPoint = `/foods`;
        await apiRequest(endPoint, data).then(async response => {

            const {data} = response.data;
            let food = this.state.food_model;
            let model = this.state.model;

            food.id = data.id;
            food.alternatives = [];
            food.quantity = '1';
            food.uuid = uuid();
            food.attributes = data.attributes;
            food.home_measure_selected = {
                value: data.measures[0],
                label: data.measures[0].measure,
                id: data.measures[0].id
            };
            food.home_measure_unit = data.measures[0].unit;
            food.measures = data.measures;
            if (!this.state.addingAlternatives) {
                model.foods.push(food);
            } else {
                this.state.alternative_food.alternatives.push(food);
            }
            this.props.notify({type: 'success', message: 'Alimento inserido com sucesso'});
            this.setState({model: model, food_model: this.resetFoodModel(), modal: false})
            await this.saveFoods();

        }).catch(err => {
            if (err.data && err.data.errors) {
                this.setState({errors: err.data.errors});
                this.props.notify({type: 'error', message: 'Verifique os campos em vermelho '});
            }
        });
    }

    resetFoodModel = () => {
        return {
            name: '',
            quantity: '',
            home_measure: '',
            calories: 0,
            proteinas: 0,
            lipidios: 0,
            carboidratos: 0,
            origem: '',
            grupo: {}
        }
    }

    addAlternative = (food) => {

        this.setState({alternative_food: food, origin_food: food, addingAlternatives: true})
    }

    renderTotalAlternatives = (food) => {

        if (food.alternatives.length === 0) {
            return (
                <></>
            );
        } else if (food.alternatives.length === 1) {
            return (
                <>
                    1 alternativa <br/>
                </>
            );
        } else {
            return (

                <>
                    {food.alternatives.length}{' '} alternativas <br/>
                </>
            );

        }
    }

    filterList = (options, props) => {
        return options;
    }

    renderMeasures = (food, origin) => {

        if (!origin) {
            origin = 'foods'
        }

        if (food.measures && food.measures.length > 0) {

            let measuresFood = food.measures.map(theMeasure => {
                return {
                    id: theMeasure.id,
                    value: theMeasure,
                    label: theMeasure.measure
                }
            })

            if (food.home_measure_selected) {
                food.home_measure_selected = measuresFood.find(measure => measure.id === food.home_measure_selected.id);
                food.home_measure_unit = food.home_measure_selected.value.unit;
            }

            if (measuresFood.length === 1) {
                food.home_measure_selected = measuresFood[0];
                food.home_measure_unit = food.home_measure_selected.value.unit;
            }

            return (
                <>
                    <Select name={'measuse' + food.uuid} value={food.home_measure_selected}
                            onChange={value => {
                                this.updateMeasure(food.uuid, value, origin)
                                this.updateFood(food.uuid, 'quantity', food.quantity, origin)
                            }}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            options={measuresFood}
                    >
                    </Select>
                </>
            )
        } else {
            return (
                <>
                    <Input value={food.home_measure}
                           size="10"
                           name={'measuse' + food.uuid}
                           onChange={(event) => {
                               this.updateMeasure(food.uuid, event.target.value)
                           }}
                           style={{width: '80px', height: '40px'}}
                    />
                </>
            );
        }

    }

    render() {
        let hasError = InputError.convertToHasClass(this.state.errors);
        let {foods} = this.state.model;
        let linkMealPlan = `/admin/patient/${this.id}/plano-alimentar`;
        return (
            <>
                <div className="content">
                    <div id="meal" style={{display: this.state.addingAlternatives ? 'none' : ''}}>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col md={8}>
                                                <HeaderProntuario
                                                    title={`Adicionar Alimento em ${this.state.model.name}`}
                                                    link={linkMealPlan}
                                                    buttonText="Voltar para o plano alimentar."
                                                />
                                                {" "}
                                            </Col>
                                            <Col md={4} style={{textAlign: 'right'}}>


                                                <Button outline color={"purle-nutrideck"} onClick={this.toggleModalImportMeal}>
                                                    <i className="far fa-copy"></i> {' '}
                                                    Importar refeição</Button>
                                            </Col>
                                        </Row>

                                        <br/>
                                        <br/>
                                        <h5>Total Kcal e % Macronutrientes</h5>
                                    </CardHeader>
                                    <CardBody>
                                        {this.renderNutrientsMeal()}
                                        <Row>
                                            <Col>
                                                <b>Tabelas: </b> {' '}
                                                <ButtonGroup>
                                                    <Button outline color={"primary"}
                                                            active={this.state.activeTable === 'TACO'}
                                                            onClick={() => {
                                                                this.setState({activeTable: 'TACO'})
                                                            }
                                                            }>Taco</Button>
                                                    <Button outline color={"primary"}
                                                            active={this.state.activeTable === 'IBGE'}
                                                            onClick={() => {
                                                                this.setState({activeTable: 'IBGE'})
                                                            }
                                                            }>IBGE</Button>
                                                    <Button outline color={"primary"}
                                                            active={this.state.activeTable === 'all'}
                                                            onClick={() => {
                                                                this.setState({activeTable: 'all'})
                                                            }
                                                            }>Todas</Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="11">
                                                <FormGroup>
                                                    <AsyncTypeahead
                                                        {...this.state.search}
                                                        ref={(ref) => this._typeahead = ref}
                                                        id="async-pagination-example"
                                                        labelKey="name"
                                                        delay={500}
                                                        useCache={false}
                                                        minLength={2}
                                                        filterBy={this.filterList}
                                                        onChange={this.handleChange}
                                                        onInputChange={this._handleInputChange}
                                                        onSearch={this._handleSearch}
                                                        placeholder="pesquise por um alimento..."
                                                        renderMenuItemChildren={option => (
                                                            <div key={option.id}>
                                                                <span>{option.name}</span>
                                                            </div>
                                                        )}
                                                        useCache={false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Button color="primary" outline type="button"
                                                        style={{margin: '1px -15px'}}
                                                        onClick={this.openModal}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            Nome do alimento
                                                        </th>
                                                        <th>
                                                            Fonte
                                                        </th>
                                                        <th>
                                                            Medida
                                                        </th>
                                                        <th>
                                                            Quantidade
                                                        </th>
                                                        <th>
                                                            Kcal
                                                        </th>
                                                        <th className='plan-meal color proteina'>
                                                            Proteínas (g)
                                                        </th>
                                                        <th className='plan-meal color lipideo'>
                                                            Lipídeos (g)
                                                        </th>
                                                        <th className='plan-meal color carboidrato'>
                                                            Carboidratos (g)
                                                        </th>
                                                        <th>
                                                            Alternativas
                                                        </th>
                                                        <th>
                                                            Ações
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {foods.map(food => {
                                                        return <tr key={food.uuid}>
                                                            <td>{food.name}</td>
                                                            <td>{food.origin}</td>
                                                            <td>
                                                                {this.renderMeasures(food)}
                                                            </td>
                                                            <td>
                                                                <Input type="number" value={food.quantity}
                                                                       step={1}
                                                                       min={"1"}
                                                                       onChange={(event) => {
                                                                           if (event.target.value < 0) {
                                                                               event.target.value = 0;
                                                                           }
                                                                           this.updateFood(food.uuid, 'quantity', event.target.value)
                                                                       }}
                                                                       style={{width: '80px', height: '40px'}}
                                                                />
                                                            </td>
                                                            <td>{food.calories}</td>
                                                            <td>{food.proteinas}</td>
                                                            <td>{food.lipidios}</td>
                                                            <td>{food.carboidratos}</td>
                                                            <td style={{textAlign: "center"}}>
                                                                {this.renderTotalAlternatives(food)}
                                                                <Link to={'#'}
                                                                      onClick={(event) => {
                                                                          event.preventDefault();
                                                                          this.addAlternative(food);
                                                                          return false;
                                                                      }}>
                                                                    {food.alternatives.length > 0 ? "visualizar" : "Adicionar"}
                                                                </Link>
                                                            </td>

                                                            <td>
                                                                <Button color="link"
                                                                        onClick={() => this.maybeDelete(food.uuid)}>
                                                                    <i className="fa fa-trash"/>
                                                                </Button>
                                                            </td>
                                                        </tr>;
                                                    })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-end">
                                            <div style={{textAlign: "right", padding: '10px'}}>

                                                <Button className="white-button"
                                                        tag={Link}
                                                        to={linkMealPlan}
                                                >Cancelar</Button>
                                                <Button color={'primary'} onClick={this.saveFoods}>Salvar</Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div id="meal_alternative" style={{display: this.state.addingAlternatives ? '' : 'none'}}>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Adicionar alternativas para alimento
                                        </CardTitle>
                                        <Link to="#" onClick={this.toogleAlternatives}>
                                            Voltar para o {this.state.model.name}
                                        </Link>
                                        <br/>
                                        <br/>
                                        <b>Selecione as alternativas para</b>
                                        <div className='plan-meal table-alternatives'>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>
                                                        Nome do alimento
                                                    </th>
                                                    <th>
                                                        Fonte
                                                    </th>
                                                    <th>
                                                        Medida Caseira
                                                    </th>
                                                    <th>
                                                        Quantidade(g)
                                                    </th>
                                                    <th>
                                                        Kcal
                                                    </th>
                                                    <th className='plan-meal color proteina'>
                                                        Proteínas (g)
                                                    </th>
                                                    <th className='plan-meal color lipideo'>
                                                        Lipídeos (g)
                                                    </th>
                                                    <th className='plan-meal color carboidrato'>
                                                        Carboidratos (g)
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.state.origin_food.name}</td>
                                                    <td>{this.state.origin_food.origin}</td>
                                                    <td>
                                                        {this.state.origin_food.home_measure}
                                                    </td>
                                                    <td>
                                                        {this.state.origin_food.quantity}
                                                    </td>
                                                    <td>{this.state.origin_food.calories}</td>
                                                    <td>{this.state.origin_food.proteinas}</td>
                                                    <td>{this.state.origin_food.lipidios}</td>
                                                    <td>{this.state.origin_food.carboidratos}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        {this.renderFoodsForm()}
                                        {this.renderAlternativeFoods()}
                                        <Row className="justify-content-end">
                                            <div style={{textAlign: "right", padding: '10px'}}>

                                                <Button className="white-button"
                                                        onClick={this.toogleAlternatives}>
                                                    Cancelar
                                                </Button>
                                                <Button color={'primary'} onClick={this.saveFoods}>Salvar</Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                <ConfirmModal message="Tem certeza que deseja remover este alimento?"
                              onConfirm={() => this.delete()}
                              onToggle={() => this.toggleConfirmation(false)}
                              open={this.state.showDeleteConfirmation}
                />

                <ImportMeal show={this.state.showModalImportMeal}
                            callbackCloseModal={this.toggleModalImportMeal}
                            mealUuid={this.mealId}
                            patient_id={this.id}/>
            </>

        )
    }

    processarCalculoNutrientes = () => {
        // total por macronutriente
        let totalLipideos = 0;
        let totalLipideosProporcao = 0;
        let totalProteinas = 0;
        let totalProteinasProporcao = 0;
        let totalCarboidratos = 0;
        let totalCarboidratosProporcao = 0;

        let totalCalorias = 0;
        this.state.model.foods.map(food => {
            totalCalorias += Number(food.calories);
            totalLipideos += Number(food.lipidios);
            totalProteinas += Number(food.proteinas);
            totalCarboidratos += Number(food.carboidratos);
            return food;
        })

        // aplica a proporcao em cada nutriente
        totalProteinasProporcao = (totalProteinas * 4);
        totalCarboidratosProporcao = (totalCarboidratos * 4);
        totalLipideosProporcao = (totalLipideos * 9);
        // console.log(totalProteinas, totalCarboidratos, totalLipideos);

        // total geral -> para calculo de proporcao, nao leva em conta as calorias
        const totalGeral = Number(totalLipideosProporcao + totalProteinasProporcao + totalCarboidratosProporcao).toFixed(2);
        // console.log('total geral ', totalGeral)
        // console.log('----');

        this.nutrientsProportions.map(nutrient => {
            if (nutrient.name === 'calorias') {
                nutrient.total = Number(totalCalorias).toFixed(2);
            }
            if (nutrient.name === 'lipideos') {
                nutrient.porcentagem = Number((((totalLipideosProporcao) / totalGeral) * 100)).toFixed(2);
                nutrient.total = Number(totalLipideos).toFixed(2);
            }
            if (nutrient.name === 'proteinas') {
                nutrient.porcentagem = Number((((totalProteinasProporcao) / totalGeral) * 100)).toFixed(2);
                nutrient.total = Number(totalProteinas).toFixed(2);
            }
            if (nutrient.name === 'carboidratos') {
                nutrient.porcentagem = Number((((totalCarboidratosProporcao) / totalGeral) * 100)).toFixed(2);
                nutrient.total = Number(totalCarboidratos).toFixed(2);
            }
            return nutrient;
        })
    }

    renderNutrientsMeal = () => {

        this.processarCalculoNutrientes();

        const calorias = this.nutrientsProportions[0];
        const lipideos = this.nutrientsProportions[1];
        const proteinas = this.nutrientsProportions[2];
        const carboidratos = this.nutrientsProportions[3];
        return (
            <>
                <Row className='text-center'>
                    <Col md='2'>Kcal</Col>
                    <Col md='2' className='plan-meal color proteina'>Proteínas (g)</Col>
                    <Col md='2' className='plan-meal color libideo'>Lipídeos (g)</Col>
                    <Col md='2' className='plan-meal color carboidrato'>Carboidratos (g)</Col>
                </Row>
                <Row className='text-center'>
                    <Col md='2'>{calorias.total}</Col>
                    <Col md='2' className='plan-meal color proteina'>{proteinas.total}</Col>
                    <Col md='2' className='plan-meal color libideo'>{lipideos.total}</Col>
                    <Col md='2' className='plan-meal color carboidrato'>{carboidratos.total}</Col>
                </Row>
                <Row className='text-center'>
                    <Col md='2'>&nbsp;</Col>
                    <Col md='2'
                         className='plan-meal color proteina'>{proteinas.porcentagem === 'NaN' ? 0 : proteinas.porcentagem}%</Col>
                    <Col md='2'
                         className='plan-meal color libideo'>{lipideos.porcentagem === 'NaN' ? 0 : lipideos.porcentagem}%</Col>
                    <Col md='2'
                         className='plan-meal color carboidrato'>{carboidratos.porcentagem === 'NaN' ? 0 : carboidratos.porcentagem}%</Col>
                </Row>
            </>
        );
    }

    renderFoodsForm = () => {
        return (
            <>
                <Row>
                    <Col>
                        <b>Tabelas: </b> {' '}
                        <ButtonGroup>
                            <Button outline color={"primary"}
                                    active={this.state.activeTable === 'TACO'}
                                    onClick={() => {
                                        this.setState({activeTable: 'TACO'})
                                    }
                                    }>Taco</Button>
                            <Button outline color={"primary"}
                                    active={this.state.activeTable === 'IBGE'}
                                    onClick={() => {
                                        this.setState({activeTable: 'IBGE'})
                                    }
                                    }>IBGE</Button>
                            <Button outline color={"primary"}
                                    active={this.state.activeTable === 'all'}
                                    onClick={() => {
                                        this.setState({activeTable: 'all'})
                                    }
                                    }>Todas</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="11">
                        <FormGroup>
                            <AsyncTypeahead
                                {...this.state.search}
                                ref={(ref) => this._typeaheadAlternative = ref}
                                id="async-pagination-example"
                                labelKey="name"
                                delay={500}
                                useCache={false}
                                minLength={2}
                                onChange={(option) => {
                                    this.handleChange(option, 'alternatives')
                                }}
                                onInputChange={this._handleInputChange}
                                onSearch={this._handleSearch}
                                placeholder="pesquise por um alimento..."
                                renderMenuItemChildren={option => (
                                    <div key={option.id}>
                                        <span>{option.name}</span>
                                    </div>
                                )}
                                useCache={false}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button color="primary" outline type="button"
                                style={{margin: '1px -15px'}}
                                onClick={this.openModal}
                        >
                            <i className="fa fa-plus"></i>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    renderAlternativeFoods = () => {
        let foods = this.state.alternative_food.alternatives ? this.state.alternative_food.alternatives : [];
        return (
            <Row>
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome do alimento
                        </th>
                        <th>
                            Fonte
                        </th>
                        <th>
                            Medida
                        </th>
                        <th>
                            Quantidade
                        </th>
                        <th>
                            Kcal
                        </th>
                        <th className='plan-meal color proteina'>
                            Proteínas (g)
                        </th>
                        <th className='plan-meal color lipideo'>
                            Lipídeos (g)
                        </th>
                        <th className='plan-meal color carboidrato'>
                            Carboidratos (g)
                        </th>
                        <th>
                            Ações
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {foods.map(food => {
                        return <tr key={food.uuid}>
                            <td>{food.name}</td>
                            <td>{food.origin}</td>
                            <td>
                                {this.renderMeasures(food, 'alternatives')}
                            </td>
                            <td>
                                <Input type="number" value={food.quantity}
                                       step={1}
                                       min={"1"}
                                       onChange={(event) => {
                                           if (event.target.value < 0) {
                                               event.target.value = 0;
                                           }
                                           this.updateFood(food.uuid, 'quantity', event.target.value, 'alternatives')
                                       }}
                                       style={{width: '80px', height: '40px'}}
                                />
                            </td>
                            <td>{food.calories}</td>
                            <td>{food.proteinas}</td>
                            <td>{food.lipidios}</td>
                            <td>{food.carboidratos}</td>
                            <td>
                                <Button outline color="primary"
                                        onClick={() => this.maybeDelete(food.uuid)}>
                                    <i className="fa fa-trash"/>
                                </Button>
                            </td>
                        </tr>;
                    })
                    }
                    </tbody>
                </Table>
            </Row>
        )
    }
}

export default withNotify(LancamentoAlimento);
