import React from "react";
import {Link} from 'react-router-dom';
import moment from 'moment';
import Slider from "react-slick";

// reactstrap components
import {Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Row,} from "reactstrap";
import apiRequest from "../../../lib/Api";
import ButtonChat from "../../../components/ButtonChat/ButtonChat";

function SliderArrows(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        />
    );
}

class HomeSpecialist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            users: []
        };
        this.settingsSlider = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            nextArrow: <SliderArrows/>,
            prevArrow: <SliderArrows/>
        }
    }

    async componentDidMount() {
        await this.refreshDashboard();
    }

    refreshDashboard = async () => {
        let appointments = await apiRequest("/specialist/dashboard", {method: "GET"});
        this.setState({appointments: appointments.data.data.appointments, users: appointments.data.data.patients});
    }



    renderNextAppointments() {

        let appointments = this.state.appointments;
        if (appointments.length === 0) {
            return (
                <div  className={'no-item'}>
                    <span><b>Você não tem consultas agendadas no momento</b></span>
                </div>
            )
        }
        return (

            <Slider {...this.settingsSlider}>
                {appointments.map(function (appoiment) {
                    if (appoiment.situation === 'missed') {
                        return;
                    }
                    return (
                        <Col md={12} key={appoiment.id}>
                            <Card className="card-appointment">
                                <CardBody>
                                    <Row>
                                        <Col md={12}><b>Paciente:</b></Col>
                                        <Col md={12}>{appoiment.user_name}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}><b>Quando:</b></Col>
                                        <Col md={12}>{moment(appoiment.datetime_start).format('LLL')} {}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}><b>Tipo:</b></Col>
                                        <Col
                                            md={12}>{appoiment.service_type === 'personal_meeting' ? 'Presencial' : 'Online'} {}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Button color="primary" outline
                                                    tag={Link}
                                                    to={`/admin/patient/${appoiment.user_id}/details`}>
                                                Ver paciente
                                            </Button></Col>
                                        <Col md={6}>


                                            <Button
                                                color="primary"
                                                tag={Link}
                                                to={`/admin/appointments/${appoiment.id}`}
                                            >
                                                Iniciar consulta
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    )
                })
                }
            </Slider>
        )
    }

    renderProblemUser() {

        let users = this.state.users;
        if (users.length === 0) {
            return (
                <div  className={'no-item'}>
                    <span><b>Você não tem pacientes que precisam de atenção no momento<br/><p className="smile-face">:)</p></b></span>
                </div>
            )
        }

        let me = this;
        const callbackFunction = async () => {
            let appointments = await apiRequest("/specialist/dashboard", {method: "GET"});
            me.setState({appointments: appointments.data.data.appointments, users: appointments.data.data.patients});
        }

        return (
            <>
                <Slider {...this.settingsSlider}>
                    {users.map(function (user) {
                        return (
                            <Col md={12} key={user.id}>

                                <Card className="card-appointment">
                                    <CardBody>
                                        <Row>
                                            <Col md={12}>
                                                <span className="bagde-mensagens-nao-respondidas">Mensagens</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}><b>Paciente:</b></Col>
                                            <Col md={12}>{user.name}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}><b>Andamento no Programa:</b></Col>
                                            <Col md={12}>{user.program_status.service} {}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}><b>Ultima consulta:</b></Col>
                                            <Col
                                                md={12}>{user.program_status.appointment ? moment(user.program_status.appointment).format('LLL') : ''} {}</Col>
                                        </Row>
                                        <Row style={{paddingBottom:'0px'}}>
                                            <Col md={6}>
                                                <Button color="primary" outline
                                                        tag={Link}
                                                        to={`/admin/patient/${user.id}/details`}>
                                                    Ver paciente
                                                </Button></Col>
                                            <Col md={6}>

                                                <ButtonChat
                                                    user={user}
                                                    messageCount={user.messages}
                                                    label="Mensagens"
                                                    onCloseCallback={() => {
                                                        callbackFunction()
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                            </Col>
                        )
                    })
                    }
                </Slider>
            </>
        )
    }


    render() {
        return (
            <>
                <div className="content dashboard-member-container home-specialist">

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md={6}>
                                            <CardTitle tag="h4">Próximos Agendamentos</CardTitle>
                                        </Col>
                                        <Col md={6}>
                                            <Button color="white"
                                                    tag={Link}
                                                    to={'/admin/appointments/calendar'}
                                                    className='float-right white-button'>Ver Todos</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className='user-appointments-card'>
                                    {this.renderNextAppointments()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md={6}>
                                            <CardTitle tag="h4">Requer sua atenção</CardTitle>
                                        </Col>
                                        <Col md={6}>
                                            <Button color="white"
                                                    onClick={this.refreshDashboard}
                                                    className='float-right white-button'>Atualizar</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className='user-appointments-card'>
                                    {this.renderProblemUser()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default HomeSpecialist;
